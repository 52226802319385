import { Link } from 'react-scroll';
import { ReactComponent as Logo } from '../assets/img/logo.svg';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="container mx-auto p-4  py-6 lg:py-8">
        <div className="grid lg:grid-cols-2 gap-20 sm:grid-cols-1">
          <div className="mb-6 md:mb-0">
            <Logo />
            <div className="font-normal font-base mt-6 opacity-50">
              At Ramp Affiliates, we specialize in creating data-driven consumer journeys that connect people, brands,
              and publishers. We simplify decision-making by providing comparison tools and optimized commerce content
              that helps consumers make informed purchasing decisions quickly and easily. With … years of experience,
              our solutions have empowered millions of people to navigate the overwhelming amount of information and
              options available when shopping online, ultimately saving them time and improving their overall shopping
              experience.
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white opacity-50">
                Services
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 font-medium">
                <li className=" cursor-pointer mb-4">
                  <Link activeClass="active" smooth spy to="services">
                    Marketing Strategy
                  </Link>
                </li>
                <li className="cursor-pointer mb-4">
                  <Link activeClass="active" smooth spy to="services">
                    Web Technology
                  </Link>
                </li>
                <li>
                  <Link activeClass="cursor-pointer active" smooth spy to="services">
                    User Acquisition
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white opacity-50">Company</h2>
              <ul className="text-gray-600 dark:text-gray-400 font-medium">
                <li className="cursor-pointer mb-4">
                  <Link activeClass="active" smooth spy to="about">
                    About
                  </Link>
                </li>
                <li className="cursor-pointer mb-4">
                  <Link activeClass="active" smooth spy to="whatwedo">
                    WhatWeDo ?
                  </Link>
                </li>
                <li>
                  <Link activeClass="cursor-pointer active" smooth spy to="workingprocess">
                    Working Process
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white opacity-50">
                Privacy & Policy
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <NavLink to="/terms"> Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy">Terms &amp; Conditions</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:items-center mt-10 justify-center align-center bg-gray-100 pt-4 pb-4">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 p-4">
          Ramp Affiliates 2024 | All Rights Reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
