import About from './About';
import BePartner from './BePartner';
import Services from './Services';
import TopSection from './TopSection';
import WhatWeDo from './WhatWeDo';
import WorkingProgress from './WorkingProgress';

const Main = () => {
  return (
    <div className="flex-grow grid items-center">
      <div>
        <TopSection />
        <section id="about">
          <About />
        </section>
        <section id="services" className="mb-20">
          <Services />
        </section>
        <section id="whatwedo" className=" container mx-auto">
          <WhatWeDo />
        </section>

        <section id="workingprocess">
          <WorkingProgress />
        </section>
        {/* <section id="contact">
          <BePartner />
        </section> */}
      </div>
    </div>
  );
};

export default Main;
