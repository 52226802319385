import contactImg from 'assets/img/contactImg.svg';
const Contact = () => {
  return (
    <div className="container mx-auto p-4 mb-24">
      <h1 className="text-2xl lg:text-9xl text-center font-bold mb-10 mt-16">Contact Us</h1>
      <div className="flex flex-col lg:flex-row justify-center gap-20 items-center">
        <img src={contactImg} style={{ height: '536px' }} />
        <div className="flex flex-col gap-6">
          <div>
            <h1 className="font-bold text-sm mb-2">Email Adress</h1>
            <p className="opacity-60">info@rampaffiliates.com</p>
          </div>
          {/* <div>
            <h1 className="font-bold text-sm mb-2">Phone Number</h1>
            <p className="opacity-60">000 00 000 00 00</p>
          </div> */}
          <h1 className="font-bold text-sm mb-2">Adresses</h1>
          <div className="flex gap-10">
            <div>
              <p className="mb-6 opacity-60">Ramp Affiliates Limited Head Office </p>
              <p className="mb-6 opacity-60">21 Engineer Ln</p>
              <p className="mb-6 opacity-60">Gibraltar GX11 1AA</p>
              <p className="mb-6 opacity-60">Gibraltar</p>
            </div>
            <div>
              <p className="mb-6 opacity-60">Ramp Affiliates Limited Irish Branch</p>
              <p className="mb-6 opacity-60">Level 1, The Chase, Carmanhall Road</p>
              <p className="mb-6 opacity-60">Sandyford Industrial Estate</p>
              <p className="mb-6 opacity-60">Dublin D18 Y3X2 Ireland</p>
            </div>
          </div>
          {/* <div>
            <h1 className="font-bold text-sm mb-2">We Are in Social Media</h1>
            <p className="opacity-60">000 00 000 00 00</p>
          </div> */}
        </div>
      </div>
      <div className="grid lg:grid-cols-2 mt-20 items-center gap-6">
        <h1 className="text-8xl ">
          You have any questions?{' '}
          <span className="font-bold" style={{ color: '#2F44FF' }}>
            let&apos;s Talk
          </span>
        </h1>
        <form action="#" className="space-y-8 text-end">
          <div>
            <input
              type="text"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Your Name"
              required
            />
          </div>
          <div>
            <input
              type="text"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Company Name"
              required
            />
          </div>
          <div>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="name@example.com"
              required
            />
          </div>

          <div className="sm:col-span-2">
            <textarea
              id="message"
              rows={6}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Leave a comment..."
            ></textarea>
          </div>
          <button
            type="submit"
            style={{ backgroundColor: '#2F44FF', height: '50px', width: '150px' }}
            className=" text-white font-bold py-2 px-4 rounded"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
