import { Navigate, useRoutes } from 'react-router-dom';

import Main from 'pages/Main';
import NotFound from 'pages/NotFound';
import { ROUTES } from 'constants/routes';
import Layout from 'layouts/Layout';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import Careers from 'pages/Careers';
import CarrersDetails from 'pages/CarrersDetails';
import Contact from 'pages/Contact';

const Routes = () => {
  return useRoutes([
    {
      element: <Layout />,
      children: [
        {
          path: ROUTES.home,
          element: <Main />,
        },

        {
          path: ROUTES.notFount,
          element: <NotFound />,
        },
        {
          path: ROUTES.terms,
          element: <Terms />,
        },
        {
          path: ROUTES.privacy,
          element: <Privacy />,
        },
        {
          path: ROUTES.contact,
          element: <Contact />,
        },
        {
          path: ROUTES.careers,
          element: <Careers />,
        },
        {
          path: ROUTES.carrersDetail,
          element: <CarrersDetails />,
        },
        {
          path: '*',
          element: <Navigate to={ROUTES.notFount} replace />,
        },
      ],
    },
  ]);
};

export default Routes;
