import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useFilter from 'hooks/useFilter';
import { Vacancies } from 'mock/vacancy';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Careers = () => {
  const [selectedJobType, setselectedJobType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [department, setDepartment] = useState('');
  const handleChange = (e: any) => {
    setselectedJobType(e.target.value);
  };
  const departmentHandler = (e: any) => {
    const { value } = e.target;
    setDepartment(value);
  };
  const searchHandler = (e: any) => {
    setInputValue(e.target.value);
  };
  const { filteredVacancies } = useFilter(selectedJobType, inputValue, department);

  return (
    <>
      <div className="container mx-auto p-10 mt-24 carrerBg lg:flex lg:justify-between px-14 rounded-md">
        <h1 className="font-bold text-4xl flex items-center cpb-6 md:pb-0   ">Search Job</h1>
        <div className="flex lg:w-3/4 mt-10 lg:mt-0">
          <input
            type="text"
            id="name"
            className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block  mr-14 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            placeholder="Job (example: Sales Manager)"
            required
            onChange={searchHandler}
          />
          <FormControl fullWidth sx={{ marginRight: '20px', backgroundColor: 'white' }}>
            <InputLabel id="demo-simple-select-label">Departments</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Departments"
              onChange={departmentHandler}
            >
              <MenuItem value={'All'}>All Departments</MenuItem>
              <MenuItem value={'Strategy'}>Strategy & BI</MenuItem>
              <MenuItem value={'Sales'}>Sales</MenuItem>
              <MenuItem value={'Marketing'}>Marketing</MenuItem>
              <MenuItem value={'HR'}>HR</MenuItem>
              <MenuItem value={'Finance'}>Finance</MenuItem>
              <MenuItem value={'IT & Engineering'}>IT & Engineering</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ width: '500px', backgroundColor: 'white' }}>
            <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Job Type"
              onChange={handleChange}
            >
              <MenuItem value={'Full Time'}>Full Time</MenuItem>
              <MenuItem value={'Part Time'}>Part Time</MenuItem>
              <MenuItem value={'Remote'}>Remote</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className=" container mx-auto p-4">
        <h1 className="font-bold text-lg mb-10 text-center">Current We Are Hiring</h1>
        <div className="grid grid-cols-1 xl:grid-cols-3  gap-24">
          {filteredVacancies.map((vacancy: any) => {
            return (
              <div className="flex flex-col justify-between rounded-md carrerBg  pt-6 pl-6 pr-6" key={vacancy.id}>
                <Link to={`/careers/${vacancy.id}`} style={{ display: 'contents' }}>
                  <div className="flex justify-between gap-10">
                    <span className="font-bold text-lg mb-10">{vacancy.position}</span>
                    <span className="font-normal text-base opacity-50">{vacancy.location}</span>
                  </div>
                  <div className="flex justify-between  pb-6">
                    <span className="font-normal text-base opacity-50">{vacancy.date}</span>
                    <span className="font-normal text-base opacity-50">{vacancy.jobType}</span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Careers;
