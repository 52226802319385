import CustomDiv from 'components/customDiv';
import Marketing from 'assets/img/marketing.svg';
import Web from 'assets/img/web.svg';
import User from 'assets/img/user.svg';
const Services = () => {
  return (
    <>
      <h1 className="text-2xl text-center mt-24 mb-12 font-extrabold">Services</h1>
      <div className="container mx-auto lg:w-2/3 grid md:grid-cols-3 sm:grid-cols:1 gap-10 ">
        <CustomDiv
          title={'Marketing and Strategy'}
          content={
            'We create comprehensive marketing strategies that use multiple channels to reach audiences across various industries on both local and global levels'
          }
          icon={Marketing}
          type="service"
        />
        <CustomDiv
          title={'Web Technology'}
          content={
            'Our team of expert tech professionals designs websites that effectively market our clients, ensuring that the final product stands out from their competitors'
          }
          icon={Web}
          type="service"
        />
        <CustomDiv
          title={'User Acquisition'}
          content={
            'We utilize various marketing tactics to drive user acquisition for a wide range of products across diverse industries.'
          }
          type="service"
          icon={User}
        />
      </div>
    </>
  );
};

export default Services;
