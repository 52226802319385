import { Vacancies } from 'mock/vacancy';
import { useEffect, useState } from 'react';

const useFilter = (selectedJobType: string, inputValue: string, department: string) => {
  const [filteredVacancies, setFilteredVacancies] = useState<any>([]);

  useEffect(() => {
    const onFilterVacancies = (selectedJobType: string, inputValue: string, department: string) => {
      const filteredData = Vacancies.filter((vacancy) => {
        return (
          (department === 'All' || vacancy.department.includes(department)) &&
          vacancy.position.toLowerCase().includes(inputValue.toLowerCase()) &&
          vacancy.jobType.includes(selectedJobType)
        );
      });

      setFilteredVacancies(filteredData);
    };
    onFilterVacancies(selectedJobType, inputValue, department);
  }, [selectedJobType, inputValue, department]);

  return { filteredVacancies };
};

export default useFilter;
