import CustomDiv from 'components/customDiv';
import Target from 'assets/img/target.svg';
import Planning from 'assets/img/planning.svg';
import Rocket from 'assets/img/rocket.svg';
import Report from 'assets/img/report.svg';

const WorkingProgress = () => {
  return (
    <>
      <h1 className="text-2xl text-center mt-24 mb-12 font-extrabold">Working Process</h1>
      <div className="container mx-auto grid lg:grid-cols-2 lg:grid-cols-4 sm:grid-cols:1 gap-6 ">
        <CustomDiv
          title={'Competitive strategy call'}
          content={
            'First, we schedule a competitive strategy call with you to discuss your business goals and how our services can help you achieve them. During this call, we can also answer any questions you have about our process and what to expect when working with us.'
          }
          type="workingProcess"
          icon={Target}
        />

        <CustomDiv
          title={'Campaign planning'}
          content={
            'Next, we move on to campaign planning, which involves in-depth research into your target audience and relevant keywords. We use AI and the expertise of our digital marketing team to gather insights into ad copy and landing page creation and optimization. We also implement measurement tracking and other tools to ensure the success of your campaigns.'
          }
          type="workingProcess"
          icon={Planning}
        />

        <CustomDiv
          title={'Campaign Launch & Optimization'}
          content={
            'Once your campaigns are launched, we monitor them daily and analyze performance metrics to identify areas for optimization. We work to improve campaign performance, reduce costs, and maximize your return on investment.'
          }
          type="workingProcess"
          icon={Rocket}
        />

        <CustomDiv
          title={'Scaling and Reporting'}
          content={
            'Finally, we provide regular reports on campaign performance in meetings with your Dedicated Account Manager. We use these meetings to make recommendations for performance upgrades and new campaign ideas, ensuring that we are always working towards your business goals.'
          }
          type="workingProcess"
          icon={Report}
        />
      </div>
    </>
  );
};

export default WorkingProgress;
