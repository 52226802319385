import Top from 'assets/img/top.svg';
import DailyRealCustomer from 'assets/img/DailyRealCustomer.svg';
import InVariousCategories from 'assets/img/InVariousCategories.svg';
import NumberPartners from 'assets/img/NumberPartners.svg';
import SatisfiedPartner from 'assets/img/SatisfiedPartner.svg';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';

const TopSection = () => {
  return (
    <div className="w-full h-full bg-cover" style={{ backgroundImage: `url(${Top})` }}>
      <div className="container mx-auto flex flex-col p-6 flex-1 pt-20">
        <h1 className="text-white text-left font-sfuidisplay font-bold text-6xl leading-tight ">
          Take Your Business To The Next Level With Our Customized
          <span
            style={{
              color: '#FFBFFC',
              textShadow:
                '0px 0px 12504.96px #FF40F7, 0px 0px 717.12px #FF40F7, 0px 0px 418.32px #FF40F7, 0px 0px 209.16px #FF40F7, 0px 0px 59.76px #FF40F7, 0px 0px 29.88px #FF40F7',
            }}
          >
            {' '}
            Digital
          </span>{' '}
          <span
            style={{
              color: '#C6D9FF',
              textShadow:
                '0px 0px 12504.96px #FF40F7, 0px 0px 717.12px #FF40F7, 0px 0px 418.32px #FF40F7, 0px 0px 209.16px #FF40F7, 0px 0px 59.76px #FF40F7, 0px 0px 29.88px #FF40F7',
            }}
          >
            Marketing
          </span>{' '}
          Solutions
        </h1>
        <p className="text-white font-normal text-base leading-normal opacity-80 w-[70%]  my-5 mb-9">
          Ramp Affiliates is a trusted network that specializes in delivering the best Digital Marketing Services to the
          customers. We provide transparent, results-focused digital marketing services that help businesses achieve
          their goals and maximize their ROI
        </p>

        <div>
          <Link activeClass="active" smooth spy to="contact">
            <button className="custom-button">
              <NavLink to="/contact">Partner With Us</NavLink>
            </button>
          </Link>
        </div>
        <div className=" grid grid-cols-2 md:grid-cols-4  mt-20 mb-5">
          <div className="flex flex-col mb-24">
            <span className=" custom-width font-extrabold   text-6xl text-white">99%</span>
            <img src={SatisfiedPartner} className="w-36" />
          </div>
          <div className="flex flex-col mb-24">
            <span className="custom-width font-extrabold   text-6xl custom-margin  text-white">100+</span>
            <img src={NumberPartners} className="w-36" />
          </div>
          <div className="flex flex-col mb-24">
            <span className="custom-width font-extrabold   text-6xl custom-margin  text-white">1000+</span>
            <img src={DailyRealCustomer} className="w-36" />
          </div>
          <div className="flex flex-col mb-24">
            <span className="custom-width font-extrabold  text-6xl custom-margin  text-white">10+</span>
            <img src={InVariousCategories} className="w-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
