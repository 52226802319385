import React, { Component } from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from 'types/errorTypes';
import ErrorMessage from './ErrorMessage';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render() {
    this.state.hasError && <ErrorMessage />;
    return this.props.children;
  }
}
