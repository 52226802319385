export const Vacancies = [
  {
    id: 1,
    position: 'Data Analytics Specialist',
    location: 'Azerbaijan',
    jobType: 'Full Time',
    department: 'Strategy',
    date: '15.05.2023 - 15.07.2023',
    content: `<h1 class="font-bold text-xl text-left" ></h1>
      <p class="font-normal text-base mb-6">
      Ramp Affiliates is looking for a junior digital marketer to join our growing team. As a Junior Digital Marketing Specialist, you’ll work on tasks that contribute to the success of digital campaigns across Google Ads, social media, SEO, email, and more. You will be working on tasks ranging from content writing to managing paid ad campaigns, competitive research, technical SEO tasks, and more. Ideally, you have a basic knowledge of digital marketing tactics and are interested in continuing to build your skills in an agency setting. You must thrive in a collaborative culture designed to make you – and us – the best. We have a diverse driven team with wildly different career paths – everyone comes to the table with a great attitude and thirst for knowledge. You must be an enthusiastic, high-energy marketer looking to increase your knowledge base and gain exposure to clients in many industries.
      </p>
      <h1 class="font-bold text-xl text-left" >Must Have</h1>
      <ul class="list-disc ml-6 mb-6">
      <li>Basic understanding of data analytics tools and techniques such as SQL, Excel, Tableau, etc.</li>
      <li>Basic knowledge of data modeling, data visualization, and data analysis.</li>
      <li>Previous work, internship, or college experience in relevant fields: data analytics, statistics, mathematics, computer science.</li>
      <li>Ability to multitask, work independently, and thrive in a fast-paced environment.</li>
      <li>Detail-oriented with strong analytical skills.</li>
      <li>Problem-solving skills, you don’t have to know the answer to every question, but you need to have the skills to find the correct answer.</li>
      <li>Comfortable interfacing with clients in a professional manner over email, video calls, and in person.</li>
    </ul>
    <h1 class="font-bold text-xl text-left mb-6">Bonus Items</h1>
    <ul class="list-disc ml-6 mb-6">
      <li>Bachelor’s Degree in Data Analytics, Statistics, Computer Science, or a related field.</li>
      <li>Experience in a client-facing role.</li>
      <li>Experience with data warehousing and ETL processes.</li>
      <li>Experience with data analysis and visualization tools such as Power BI, Google Analytics, Adobe Analytics, etc.</li>
      <li>Experience with programming languages such as Python or R.</li>
      <li>Experience with cloud-based platforms such as AWS or Azure.</li>
      <li>Comfortable with public speaking and delivering presentations.</li>
      <li>Quick wit, good sense of humor, strong Pictionary, and cornhole skills.</li>
    </ul>
      <h1 class="font-bold text-xl text-left mb-6" >Note</h1>
      <p class="font-normal text-base mb-6">Missing a must have or two? Not a problem – convince us why you’re the best fit for the team. We are willing to train the right candidate. Compensation package is based on skill set and experience and includes health and dental insurance, opportunity to earn bonuses, and a well-stocked snack and beverage bar.</p>
      <h1 class="font-bold text-xl text-left mb-6" >How to apply?</h1>
      <p class="font-normal text-base">
      If you find yourself suitable for this job position, please send your resume to <span class='textColor'>hr@rampaffiliates.com</span> Adress with <span class='font-bold'>“Data Analytics Specialist” </span>Title 
      </p>
      `,
  },
  {
    id: 2,
    position: 'Finance Assistant',
    location: 'Azerbaijan',
    jobType: 'Full Time',
    department: 'Finance',
    date: '15.05.2023 - 15.07.2023',
    content: `<h1 class="font-bold text-xl text-left mb-6" >About Us</h1>
      <p class="font-normal text-base mb-6">
      Ramp Affiliates is looking for a junior digital marketer to join our growing team. As a Junior Digital Marketing Specialist, you’ll work on tasks that contribute to the success of digital campaigns across Google Ads, social media, SEO, email, and more. You will be working on tasks ranging from content writing to managing paid ad campaigns, competitive research, technical SEO tasks, and more. Ideally, you have a basic knowledge of digital marketing tactics and are interested in continuing to build your skills in an agency setting. You must thrive in a collaborative culture designed to make you – and us – the best. We have a diverse driven team with wildly different career paths – everyone comes to the table with a great attitude and thirst for knowledge. You must be an enthusiastic, high-energy marketer looking to increase your knowledge base and gain exposure to clients in many industries.
      </p>
      <h1 class="font-bold text-xl text-left mb-6" >Responsibilities</h1>
      <p class="font-normal text-base">
<ul class="list-disc ml-6 mb-6">
  <li>Prepare financial documents, such as invoices, bills, and accounts payable/receivable</li>
  <li>Process and track payments and expenses</li>
  <li>Assist with bank reconciliations</li>
  <li>Maintain accurate records of financial transactions</li>
  <li>Support budgeting and forecasting activities</li>
  <li>Conduct financial analysis and research as requested</li>
  <li>Assist with the preparation of financial reports</li>
  <li>Assist in tax filings and compliance with local, state, and federal regulations</li>
</ul>

<h1 class="font-bold text-xl text-left mb-6">Requirements</h1>
<ul class="list-disc ml-6 mb-6">
  <li>Bachelor's degree in Finance, Accounting or related field</li>
  <li>Strong knowledge of finance and accounting principles</li>
  <li>Proficiency in MS Excel and accounting software (e.g. QuickBooks)</li>
  <li>Attention to detail and accuracy in work</li>
  <li>Excellent organizational, problem-solving, and analytical skills</li>
  <li>Ability to manage time effectively and prioritize tasks in a fast-paced environment</li>
  <li>Strong communication skills and ability to work in a team environment</li>
  <li>Ability to maintain confidentiality of sensitive information</li>
  <li>Experience in a similar role is preferred but not required</li>
</ul>

      </p>
      <h1 class="font-bold text-xl text-left mb-6" >Note</h1>
      <p class="font-normal text-base mb-6">
      Missing a must have or two? Not a problem – convince us why you’re the best fit for the team. We are willing to train the right candidate. Compensation package is based on skill set and experience and includes health and dental insurance, opportunity to earn bonuses, and a well-stocked snack and beverage bar.
      </p>
      <h1 class="font-bold text-xl text-left mb-6" >Must Have</h1>
      <p class="font-normal text-base">
      If you find yourself suitable for this job position, please send your resume to <span class='textColor'>hr@rampaffiliates.com</span> Adress with <span class='font-bold'>“Finance Assistant” </span>Title 
      </p>
      `,
  },
  {
    id: 3,
    position: 'Junior Digital Marketing Specialist',
    location: 'Azerbaijan',
    jobType: 'Full Time',
    department: 'Marketing',
    date: '15.05.2023 - 15.07.2023',
    content: `<h1 class="font-bold text-xl text-left" >About Us</h1>
      <p class="font-normal text-base mb-6">
      Ramp Affiliates is looking for a junior digital marketer to join our growing team. As a Junior Digital Marketing Specialist, you’ll work on tasks that contribute to the success of digital campaigns across Google Ads, social media, SEO, email, and more. You will be working on tasks ranging from content writing to managing paid ad campaigns, competitive research, technical SEO tasks, and more. Ideally, you have a basic knowledge of digital marketing tactics and are interested in continuing to build your skills in an agency setting. You must thrive in a collaborative culture designed to make you – and us – the best. We have a diverse driven team with wildly different career paths – everyone comes to the table with a great attitude and thirst for knowledge. You must be an enthusiastic, high-energy marketer looking to increase your knowledge base and gain exposure to clients in many industries.
      </p>
      <h1 class="font-bold text-xl text-left mb-6" >Must Have</h1>
      <p class="font-normal text-base">
        <ul class="list-disc ml-6 mb-6">
        <li>Basic understanding of Google Ads, SEO, Facebook Ads, etc.</li>
        <li>Basic knowledge of basic digital marketing principles – competitive research, keyword research, etc.</li>
        <li>Basic understanding of different digital marketing KPIs and analytics</li>
        <li>Previous work, internship, or college experience in relevant fields: media, marketing, promotions, writing, design, ad agency environment</li>
        <li>Copy/content writing skills</li>
        <li>Ability to multitask, work independently, and thrive in a fast-paced environment</li>
        <li>Detail-oriented with strong analytical skills</li>
        <li>Problem-solving skills, you don’t have to know the answer to every question, but you need to have the skills to find the correct answer</li>
        <li>Comfortable interfacing with clients in a professional manner over email, video calls, and in person</li>
        </ul>
      </p>
      <h1 class="font-bold text-xl mb-6 text-left" >Bonus Items</h1>
      <p class="font-normal text-base">
      <ul class="list-disc ml-6 mb-6">
      <li>Bachelor’s Degree</li>
      <li>Experience in a client-facing role</li>
      <li>Project management and CRM tools</li>
      <li>Experience with reporting and auditing tools (Moz, SemRush, SpyFu, Screaming Frog, Swydo, etc.)</li>
      <li>Social media management experience, experience building social audiences/interaction and creating organic social post content</li>
      <li>Graphic design skills (Adobe Photoshop, Illustrator, etc.)</li>
      <li>Certified in Google Ads</li>
      <li>Basic HTML knowledge</li>
      <li>WordPress knowledge</li>
      <li>Comfortable with public speaking and delivering presentations</li>
      <li>Quick wit, good sense of humor, strong Pictionary, and cornhole skills</li>
    </ul>
       
      </p>
      <h1 class="font-bold text-xl text-left mb-6" >Note</h1>
      <p class="font-normal text-base mb-6">
      Missing a must have or two? Not a problem – convince us why you’re the best fit for the team. We are willing to train the right candidate. Compensation package is based on skill set and experience and includes health and dental insurance, opportunity to earn bonuses, and a well-stocked snack and beverage bar.
      </p>
      <h1 class="font-bold text-xl text-left mb-6" >How to apply?</h1>
      <p class="font-normal text-base">
      If you find yourself suitable for this job position, please send your resume to <span class='textColor'>hr@rampaffiliates.com</span> Adress with <span class='font-bold'>“Junior Digital Marketing Specialist” </span>Title 
      </p>
      `,
  },
];
