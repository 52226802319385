import { useParams } from 'react-router-dom';
import { Vacancies } from 'mock/vacancy';

const CarrersDetails = () => {
  const { id } = useParams();
  const content = Vacancies.find((vacancy) => String(vacancy.id) === id);
  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="font-bold text-3xl text-left mb-10">{content?.position}</h1>
      {content && <div dangerouslySetInnerHTML={{ __html: content?.content }} />}
    </div>
  );
};

export default CarrersDetails;
