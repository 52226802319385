import CustomSection from 'components/customSection';
import AboutImg from 'assets/img/about.svg';

const About = () => {
  const content = `At Ramp Affiliates, we specialize in creating data-driven consumer journeys that connect people, brands, and publishers. We simplify decision-making by providing comparison tools and optimized commerce content that helps consumers make informed purchasing decisions quickly and easily. With 2 years of experience, our solutions have empowered millions of people to navigate the overwhelming amount of information and options available when shopping online, ultimately saving them time and improving their overall shopping experience.`;

  return <CustomSection title={'About Us'} content={content} imgUrl={AboutImg} />;
};

export default About;
