export const ROUTES = {
  home: '/',
  careers: '/careers',
  carrersDetail: '/careers/:id',
  about: '/about',
  bePartner: '/partner',
  terms: '/terms',
  privacy: '/privacy',
  contact: '/contact',
  notFount: '/404',
};
