interface IProps {
  buttonText: string;
  icon: boolean;
  size: 'large' | 'small';
}
// eslint-disable-next-line react/prop-types
const CustomButton: React.FC<IProps> = ({ buttonText, icon, size }) => {
  return (
    <button
      className={`flex items-center justify-center font-normal font-bold  text-blue-500 text-center ${
        size === 'large' ? 'text-base' : 'text-sm'
      }`}
    >
      <span>{buttonText}</span>
      {icon && (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M9.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L11.586 11H3a1 1 0 010-2h8.586l-2.293-2.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
};

export default CustomButton;
