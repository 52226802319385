import CustomDiv from 'components/customDiv';
import CustomSection from 'components/customSection';
import Data from 'assets/img/data.svg';
import Deals from 'assets/img/deals.svg';
import Industry from 'assets/img/industry.svg';
import Last from 'assets/img/last.svg';
const WhatWeDo = () => {
  const content = `At Ramp Affiliates, we specialize in delivering comprehensive digital marketing solutions tailored to meet your unique business needs. Our expert team utilizes cutting-edge strategies and innovative techniques to boost your online presence, drive targeted traffic, and maximize conversions. Whether it's SEO, social media marketing, PPC advertising, or content creation, we're dedicated to helping your business thrive in the digital landscape.`;

  return (
    <div className="xl:relative flex flex-col gap-10  justify-center items-center  xl:h-[840px]   ">
      <div className=" order-2  xl:absolute xl:w-80  xl:top-12 sm:top-6 xl:left-10 sm:left-10 boxShadow ">
        <CustomDiv
          title="Data Analytics and Insights:"
          icon={Data}
          type="whatWeDo"
          content="A data-driven approach involves using relevant data to inform decision-making and problem-solving. By analyzing large volumes of data, businesses can gain valuable insights and make informed decisions to drive innovation and gain a competitive edge."
        />
      </div>
      <div className="order-3  xl:absolute  xl:w-80  xl:rightBox sm:top-16 xl:right-10 sm:right-10 boxShadow ">
        <CustomDiv
          title="Performance Tracking and Optimization "
          icon={Deals}
          type="whatWeDo"
          content="Our dedicated team utilizes advanced tracking technologies to monitor and analyze key performance indicators (KPIs) in real-time. From website traffic and click-through rates to conversion rates and customer engagement metrics, we leave no metric unmeasured. This data-driven approach enables us to gain deep insights into campaign performance and identify areas for improvement."
        />
      </div>
      <div className="order-4  xl:absolute  xl:w-80  bottomLeftBox xl:left-20 sm:left-10 boxShadow ">
        <CustomDiv
          title="Industry Experience"
          icon={Industry}
          type="whatWeDo"
          content="From the ever-evolving world of technology to the fast-paced realm of e-commerce and beyond, we've partnered with clients across diverse sectors. We leverage this knowledge to stay one step ahead and create innovative marketing approaches that position your business for success."
        />
      </div>
      <div className="order-5  xl:absolute  xl:w-80  bottomRightBox xl:right-32 sm:right-10 boxShadow ">
        <CustomDiv
          title="Data-Driven Decision Making"
          icon={Last}
          type="whatWeDo"
          content="By harnessing the power of data, we gain deep insights into your target audience, campaign performance, and market trends. We analyze vast amounts of data, from website analytics and customer behavior to social media metrics and campaign results, to paint a comprehensive picture of your digital landscape."
        />
      </div>
      <div className="order-1 sm:order-1">
        <CustomSection title={'What We Do ?'} content={content} />
      </div>
    </div>
  );
};

export default WhatWeDo;
