import React from 'react';
import { CustomDivProps } from 'types/errorTypes';
import CustomButton from './customButton';

const CustomDiv: React.FC<CustomDivProps> = ({ type, icon, title, content }) => {
  if (type === 'whatWeDo') {
    return (
      <div className={'flex flex-col items-start gap-4 bg-white pt-6 pl-6 pr-8 pb-8 boxShadow'}>
        <div className={'flex items-center gap-4'}>
          {<img src={icon} />}
          <h1>{title}</h1>
        </div>
        <p className={' text-left opacity-50 text-sm'}>{content}</p>
      </div>
    );
  }
  if (type === 'workingProcess') {
    return (
      <div className={'flex flex-col items-center bgColor pl-8 pr-8 pb-6'}>
        <div className={'flex flex-col items-center'}>
          {<img src={icon} className={'mt-[-25px]'} />}
          <h1 className={'text-xl font-bold text-center pt-8'}>{title}</h1>
        </div>
        <p className={' mt-6 text-center opacity-50 text-base'}>{content}</p>
      </div>
    );
  }
  return (
    <div className={'flex flex-col items-center bgColor pt-6 pl-8 pr-8 pb-10'}>
      <div className={'flex items-center gap-4'}>
        {<img src={icon} />}
        <h1 className={'flex flex-col items-center gap-4'}>{title}</h1>
      </div>
      <p className={' mt-6 text-center opacity-50 text-base pb-12'}>{content}</p>
      {/* {<CustomButton buttonText={'Learn More'} icon={true} size="small" />} */}
    </div>
  );
};

export default CustomDiv;
