import { CustomDivProps } from 'types/errorTypes';
import React from 'react';
import CustomButton from './customButton';

type CustomSectionProps = {
  imgUrl?: string;
  title: string;
  content: string;
  buttonText?: string;
};

const CustomSection: React.FC<CustomSectionProps> = ({ imgUrl, title, content, buttonText }) => {
  return (
    <div className=" container mx-auto mb-10 flex-1  mt-[96px] ">
      <div className={`bgColor flex flex-col sm:flex-row justify-between gap-8   ${!imgUrl ? 'centerText ' : 'pl-10'}`}>
        <div className={`w-full flex flex-col mt-14 mb-16 justify-center ${!imgUrl ? ' sm:w-1/2 mx-auto' : ''}`}>
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-center text-gray-800 pb-4">{title}</h1>
          <p className="font-normal text-center leading-6 text-gray-600 p-4">{content}</p>
          {buttonText && (
            <div className="mt-10 flex justify-center items-center">
              <CustomButton buttonText={buttonText} icon={true} size="small" />
            </div>
          )}
        </div>
        {imgUrl && (
          <div className="w-full lg:w-8/12 mt-[-160px]">
            <img className="w-full h-full" src={imgUrl} alt="A group of People" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSection;
